@import "variables.scss";
@import "_1variables.scss";
@import "mixins.scss";
@import "bs-datepicker.css";
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,300;1,100&display=swap');
@import "@ng-select/ng-select/themes/default.theme.css";
@import "2bootstrap-config";

//--------------------------------------------------------------------------------------------------------
//                                                 Basis style
//--------------------------------------------------------------------------------------------------------
body {
    padding-top: 3em;
    color: var(--color-text);
    background-color: var(--color-body-background);
    font-family: 'Poppins', "roboto-Regular", Georgia, serif;
    font-size: 12px;
    margin: revert;
    @include respond-below(sm) {
    }
}

.page-information {
    margin-top: 1em;
    margin-bottom: 2em;
}

.page-information-commentaire{
    font-size: 1.2em;
}

/* Modifier la couleur et la taille du placeholder */
input::placeholder {
    color: var(--color-grey-5) !important; /* Couleur du texte du placeholder #888*/
    font-style: italic; /* Style de la police */
}

.page-information-commentaire-rouge{
    color: var(--color-red-lg);
}

.breadcrumb-container{
    padding-top: 4em;
    background-color: #FFFFFF;
    padding-bottom: 1em;
}

h1,
h2,
h3,
h4 {
  color: var(--color-titles);
  font-size: 1rem;
}

h4 {
  &::before {
    background-color: var(--color-titles);
    content: "";
    padding: 0 0.5em;
    margin: 0 0.125rem;
    -webkit-mask-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' height='0.6rem' viewBox='0 0 16 16'%3E%3Cpath d='m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z'/%3E%3C/svg%3E");
    mask-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' height='0.6rem' viewBox='0 0 16 16'%3E%3Cpath d='m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z'/%3E%3C/svg%3E");
    -webkit-mask-repeat: no-repeat;
    mask-repeat: no-repeat;
    -webkit-mask-position: center;
    mask-position: center;
    clip-path: padding-box inset(0.28125em 0);
  }
}

// Mise en forme du ng-select pour ressembler aux composants Bootstrap 5
.ng-select {
  .ng-select-disabled > .ng-select-container {
    background-color: #e9ecef;
  }

  &.is-invalid .ng-select-container {
    border-color: red !important;
  }
}

input[disabled],
button[disabled] {
  cursor: not-allowed;
}

.cursor-pointer {
  cursor: pointer !important;
}

.separator {
  border-bottom: 1px solid var(--color-th-1);
}

.container-background-1 {
  background-color: var(--color-container-background-1);
}

//--------------------------------------------------------------------------------------------------------
//                                                Redefined scrollbar style
//--------------------------------------------------------------------------------------------------------
*::-webkit-scrollbar {
  width: 6px;
  height: 6px;
}

*::-webkit-scrollbar-track {
  background: var(--color-scrollbar-BG);
  width: 15px;
  height: 15px;
}

*::-webkit-scrollbar-button {
  width: 1rem;
  height: 1rem;
  background-color: transparent;
  border: none;
}

*::-webkit-scrollbar-thumb {
  background-color: var(--color-scrollbar-thumbBG);
  border-radius: 3px;
  border: 1px solid var(--color-scrollbar-thumbBorder);
}

//--------------------------------------------------------------------------------------------------------
//                                                Redefined bootstrap style
//--------------------------------------------------------------------------------------------------------
.btn {
  min-width: 5rem;

  &.btn-success {
    background-color: var(--color-success);
    background: linear-gradient(
                    180deg,
                    var(--color-success) 0%,
                    var(--color-success-light) 40%
    );
    border: 1px solid var(--color-light);

    &:hover {
      background-color: var(--color-success-light);
    }
  }

  &.btn-info {
    background-color: var(--color-info);
    color: var(--color-light);
    border: 1px solid var(--color-light);

    &:hover {
      background-color: var(--color-info-light);
    }
  }

  &.btn-validate {
    img {
      width: 1rem;
    }
  }

  @include respond-below(sm) {
    min-width: auto;
    &.pastille {
      height: 2.8rem;
      min-width: auto;
      border-radius: 50%;
      padding: 0.5rem;
      width: 2.8rem;

      img {
        width: 1.2rem;
      }
    }
  }
}

.form-control {
  font-size: inherit;
}

.input-group-text {
  font-size: inherit;
  background-color: var(--color-bootstrap-bkg-input);
  border: 1px solid var(--color-bootstrap-input-border);
}

::placeholder {
  font-style: italic;
  color: rgba(181, 181, 181) !important;
}

.checkbox {
  font-size: inherit !important;
}

.text-info {
  color: var(--color-text-info) !important;
}

.text-danger {
  color: var(--color-ko) !important;
}

//--------------------------------------------------------------------------------------------------------
//                                                Header style
//--------------------------------------------------------------------------------------------------------
header {
  flex-basis: auto;
  position: fixed;
  top: 0;
  width: 100%;
  flex-grow: 1;
  background-color: var(--color-container-background);
  min-height: 6rem;
  max-height: 7rem;
  box-shadow: 8px 0 6px var(--color-shadow);
  z-index: 25;
  @include respond-below(sm) {
    min-height: 4.2rem;
  }
}

//--------------------------------------------------------------------------------------------------------
//                                    Styling bootstrap popover and tooltip
//--------------------------------------------------------------------------------------------------------
.container-fluid {
  padding: 0;
}

.container-fluid-layout{
    background: #FFFFFF;
    min-height: 80vh;
}

.popoverTooltipClass {
  cursor: pointer;

  &.popover {
    box-shadow: 0.3rem 0.3rem 0.5rem var(--color-shadow);
  }
}

.tooltipClass {
  .tooltip-inner {
    color: var(--color-font-1) !important;
    background-color: var(--color-light) !important;
    box-shadow: 0.3rem 0.3rem 0.5rem var(--color-shadow);

    .out-color {
      color: var(--color-ko) !important;
    }

    .in-color {
      color: var(--color-success-strong) !important;
    }
  }
}

.tooltip-row {
  .tooltip-inner {
    color: var(--color-light) !important;
    background-color: var(--color-info) !important;
  }
}

//--------------------------------------------------------------------------------------------------------
//                                    Styling breadCrump
//--------------------------------------------------------------------------------------------------------
lib-breadcrumbs {
  padding: 0;
  min-width: 100%;
  display: inline-block;

  ol {
    list-style: none;
    padding-left: 1rem;
    margin-top: 1rem;
    margin-bottom: 1rem;

    li {
      display: inline;
      color: var(--color-breadcrump);
      font-size: 1rem;
      margin-left: 0.1rem;

      a {
        color: var(--color-breadcrump-a);

        &:hover {
          color: var(--color-breadcrump-a-hover);
          text-decoration: none;
        }
      }

      &:not(:first-child) {
        ::before {
          background-color: var(--color-breadcrump-a);
          content: "";
          padding: 0 0.5em;
          margin: 0 0.125rem;
          -webkit-mask-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' height='0.6rem' viewBox='0 0 16 16'%3E%3Cpath d='m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z'/%3E%3C/svg%3E");
          mask-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' height='0.6rem' viewBox='0 0 16 16'%3E%3Cpath d='m12.14 8.753-5.482 4.796c-.646.566-1.658.106-1.658-.753V3.204a1 1 0 0 1 1.659-.753l5.48 4.796a1 1 0 0 1 0 1.506z'/%3E%3C/svg%3E");
          -webkit-mask-repeat: no-repeat;
          mask-repeat: no-repeat;
          -webkit-mask-position: center;
          mask-position: center;
          clip-path: padding-box inset(0.28125em 0);
        }
      }
    }
  }

  @include respond-below(sm) {
    padding: 0;
    ol {
      padding-left: 0;

      li {
        font-size: 1rem;
      }
    }
  }
}

@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 950px !important;
  }
}

//--------------------------------------------------------------------------------------------------------
//                                    Styling datePicker
//--------------------------------------------------------------------------------------------------------

.theme-blue {
  .bs-datepicker-head {
    background-color: var(--color-datepicker-bck1) !important;
  }
}

.theme-error {
  .bs-datepicker-head {
    background-color: var(--color-error) !important;
  }
}

// some fixed height
.container-fixed-height1 {
  max-height: 300px;
  overflow-y: auto;
}

.datepicker table tr td.new,
.datepicker table tr td.old {
  height: 0;
  line-height: 0;
  visibility: hidden;
}

//--------------------------------------------------------------------------------------------------------
//                                    Styling toaster
//--------------------------------------------------------------------------------------------------------
.toast-success {
  background-color: var(--color-success) !important;
}

.toast-error {
  background-color: var(--color-ko) !important;
}

//--------------------------------------------------------------------------------------------------------
//                                    CSS effects
//--------------------------------------------------------------------------------------------------------
// Blink
.blink-success {
  -webkit-animation: blink-success 2s linear 0s;
  -moz-animation: blink-success 2s linear 0s;
  -ms-animation: blink-success 2s linear 0s;
  -o-animation: blink-success 2s linear 0s;
  animation: blink-success 2s linear 0s;
}

@keyframes blink-success {
  0% {
    box-shadow: none;
  }
  25% {
    box-shadow: 0rem 0rem 1rem var(--color-success);
  }
  50% {
    box-shadow: none;
  }
  75% {
    box-shadow: 0rem 0rem 1rem var(--color-success);
  }
  100% {
    box-shadow: none;
  }
}

@-webkit-keyframes blink-success {
  0% {
    box-shadow: none;
  }
  25% {
    box-shadow: 0rem 0rem 1rem var(--color-success);
  }
  50% {
    box-shadow: none;
  }
  75% {
    box-shadow: 0rem 0rem 1rem var(--color-success);
  }
  100% {
    box-shadow: none;
  }
}

.orga_menu {
  margin-top: 5px;
  margin-bottom: 0px;
  border: 0;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.orga_field0 {
  font-family: 'Poppins';
  fill: #3366cc;
  font-size: 20px;
}

.orga_field1 {
  font-family: 'Poppins';
  fill: #3366cc;
  font-size: 14px;
}

.orga_field2 {
  font-family: 'Poppins';
  fill: #3366cc;
  font-size: 14px;
}

.orga_field3 {
  font-family: 'Poppins';
  fill: #3366cc;
  font-size: 14px;
}

.orga_field4 {
  font-family: 'Poppins';
  fill: #3366cc;
  font-size: 10px;
}

.orga_field6 {
  font-family: 'Poppins';
  fill: #3366cc;
  font-size: 12px;
  font-weight: bold;
}

/* border color */
div.boc-light .boc-input > input {
  border-color: #FFB615;
}

/* input field on focus */
div.boc-light .boc-input > input:focus {
  border-color: #FFB615;
}

/* the search label on focus */
.boc-light .boc-input > label.focused, .boc-dark .boc-input > label.focused {
  color: #acacac !important;
}

/* the hovered search resutt */
.boc-light .boc-search [data-search-item-id]:hover, .boc-light .boc-search [data-selected=yes] {
  background-color: #acacac !important;
}

// Container portail rh
.portailrh-container-global {
    width: 98%;
    text-align: justify;
    margin-left: 1rem;
    margin-bottom: 1rem;
}

.portailrh-container-page {
    border: 1px solid #efefef;
    padding-bottom: 1em;
    padding-top: 1em;
}
