:root {
  //Base colors
  --color-light: #fff;
  --color-light-hover: rgba(255, 255, 255, 0.7);
  --color-black: #000;
  --color-dark: #353535;
  --color-font-1: #212529;
  --color-font-1-hover: #535455;
  --color-font-1-lighted: #c0c0c0;
  --color-grey-1: rgb(37, 48, 59);
  --color-grey-2: #495057;
  --color-grey-3: #ced4da; // bootstrap input border color
  --color-grey-4: #dee2e6; //bootstrap tables's cell border color
  --color-grey-5: rgb(104, 117, 131);
  --color-grey-transparent: rgb(246, 246, 246);
  --color-grey-transparent-2: rgba(69, 69, 69, 0.3);
  --color-red-lg: #cc0033; // couleur logo Leon Grosse
  --color-red-lg-light: #f18fa7; // couleur logo Leon Grosse
  --color-red-lg-light: rgba(227, 27, 24, 0.5); // red hover
  --color-complementary-1: #3366cc; //bleuet selon doc charte graphique
  --color-complementary-1-hover: rgba(51, 102, 204, 0.5); //bleuet light (hover)
  --color-complementary-1-light: #678fdd; //bleuet light (hover)
  --color-complementary-1-light2: #9cb6eb; //bleuet tres light (hover)
  --color-complementary-1-light3: #edf2fd; //bleuet tres tres light (hover)
  --color-complementary-1-light4: #fafcff; //bleuet tres tres tres light ()
  --color-complementary-2: #663366; //parme selon doc charte graphique
  --color-complementary-2-light: #865486; //parme light
  --color-complementary-2-light2: #b695b6; //parme light un peu plus
  --color-complementary-2-light3: #f5e7f5; //parme extrêêêmement light
  --color-complementary-2-hover: rgba(102, 51, 102, 0.5); //parme light (hover)
  --color-complementary-3: #99cc00; //vert chartreuse selon doc charte graphique
  --color-complementary-3-strong: rgb(
                  92,
                  204,
                  0
  ); //vert chartreuse selon doc charte graphique
  --color-complementary-3-hover: rgba(
                  153,
                  204,
                  0,
                  0.8
  ); //vert chartreuse  light (hover)
  --color-complementary-3-light: rgb(
                  244,
                  252,
                  178
  ); //vert chartreuse  very-light
  --color-complementary-3-light2: #ddfc80;
  --color-complementary-4: #ffcc00; //jaune soleil selon doc charte graphique
  --color-complementary-4-hover: rgba(
                  255,
                  204,
                  0,
                  0.5
  ); //jaune soleil light (hover)
  --color-complementary-5: #999999;

  //---------------------------------------------------------------------------------------------------------
  //                          Assignation des couleurs :
  //---------------------------------------------------------------------------------------------------------
  // couleurs  ok / ko
  --color-ko: var(--color-red-lg);
  --color-ko-light: var(--color-red-lg-light);
  //vert
  --color-success: var(--color-complementary-3);
  --color-success-light: var(--color-complementary-3-hover);
  --color-success-strong: var(--color-complementary-3-strong);

  // Bouton 'info' (pas vraiment action)
  --color-info: var(--color-complementary-1);
  --color-info-light: var(--color-complementary-1-light);
  --color-info-strong: var(--color-complementary-1-strong);

  // couleur du body :
  --color-body-background: var(--color-complementary-1-light3);

  // couleur de fond des containers (des pages)
  --color-container-background: var(--color-light);

  // couleur du texte de base.
  --color-text: var(--color-complementary-1-light);

  // Titres (h1, h2)
  --color-titles: var(--color-complementary-1);
  --color-titles-2: var(--color-light);
  // Couleur des ombres (box-shadow)
  --color-shadow: var(--color-grey-transparent-2);

  // Tableaux :
  //--> couleur font et bordure de thead des tableaux
  --color-thead: var(--color-complementary-1-light);
  //--> couleurs background thead (dégradé si 2 couleurs différentes)
  --color-th-1: var(
                  --color-complementary-1-light3
  ); // th background, border, shadow
  --color-th-2: var(--color-complementary-1-light3); // th  gradient background
  --color-cell: var(--color-light);
  --color-row-hover: var(--color-grey-transparent);
  --color-row-highlighted: var(--color-complementary-3-light);
  --color-row-highlighted-hover: var(--color-complementary-3-light2);

  // header colors:
  --color-header-font: var(--color-complementary-1);
  --color-header-font-hover: var(--color-complementary-1-hover);
  --color-header-border: transparent;

  // leftmenu colors
  //--> background
  --color-leftmenu-bck: var(--color-grey-1); //var(--color-complementary-1);
  --color-leftmenu-font: var(--color-grey-3);
  --color-leftmenu-item-hover: var(--color-complementary-1-light2);
  --color-leftmenu-font-hover: var(--color-light);
  --color-leftmenu-item-active: var(
                  --color-grey-5
  ); //var(--color-complementary-1-light);
  --color-leftmenu-elements: var(--color-grey-1);

  //bootstrap predefined colors.
  // info
  --color-text-info: var(--color-font-1);
  //--> table row
  --color-bootstrap-input-border: var(--color-grey-3);
  //--> table cell border
  --color-bootstrap-cell: var(--color-grey-4);
  //--> grey btn hover
  --color-bootstrap-bkg-hover: var(--color-complementary-1-light2);
  // --> font color in input field
  --color-bootstrap-bkg-input: var(--color-complementary-1-light3);
  --color-font-bootstrap-input: var(--color-grey-2);

  // fil d'ariane (couleur textes)
  --color-breadcrump: var(--color-complementary-1);
  --color-breadcrump-a: var(
                  --color-grey-3
  ); // (clair ou var(--color-grey-5); (plus foncé)
  --color-breadcrump-a-hover: var(--color-complementary-1-hover);

  //input type select list elements / items.
  --color-list-bkg: var(--color-complementary-1-light2);
  --color-list-bkg-hover: var(--color-complementary-1-light);
  --color-list-bkg-active: var(--color-complementary-1);

  // datepicker
  --color-datepicker-bck1: var(--color-complementary-1-hover);

  // scrollbar style
  --color-scrollbar-BG: var(--color-light);
  --color-scrollbar-thumbBG: var(--color-complementary-1-hover);
  --color-scrollbar-thumbBorder: var(--color-grey-3);
}
